.pdf-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.pdf-select {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
}

.pdf-file-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 80%;
    text-align: center;
}

.pdf-file-item span {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.pdf-viewer {
    width: 100%;
    height: 500px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}