body,
html {
  margin: 0;
  padding: 0;
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
}

.section-1 {
  background-image: url('./../assets/image1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 915px;
}

.container {
  margin-top: 20%;
}
.section1-content p {
  color: white;
  font-size: 2rem;
}

.section-2 {
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
  min-height: 731px;
  overflow: hidden;
}

.wrapper2 {
  margin: 7% auto;
}

.section-3 {
  min-height: 440px;
  width: 80%;
  margin: 0 auto;
  background: #c991fb;
  /* background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%); */
}

.section3-container {
  margin-top: 3%;
  min-height: 440px;
}

.section-4 {
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
  min-height: 787px;
}
.wrapper4 {
  margin: 5% auto;
}

h6,
h2 {
  color: #fff;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}
h6 {
  font-weight: bolder;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .text {
    font-size: 1.25rem;
    margin-left: 0;
  }

  .parent {
    display: flex;
    flex-direction: row-reverse;
  }
}
