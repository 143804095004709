.login-box {
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80vh;
}

.login-container .login-header {
  font-size: 1.7rem;
  margin-bottom: 40px;
  color: #ffff00;

  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.login-container {
  max-width: 500px;
  height: fit-content;
  padding: 20px;
  border-radius: 5px;
  width: 450px;
  max-width: 90%; /* Ensure it doesn't go wider than the viewport */
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 30px;
}

.add-option {
  display: flex;
  justify-content: space-between;
  color: #8b8b8b;
  margin-top: 5px;
}

input[type='checkbox'] {
  border: 1px solid #8b8b8b;
}

#checkbox {
  background-color: red;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #8b8b8b;
  border-radius: 5px;
  background-color: transparent;
}

a {
  text-decoration: none;
  font-size: 1rem;
  color: #8b8b8b;
  text-align: left;
}

p {
  font-size: 0.6rem;
  margin-top: 10px;
}

.login-text {
  color: white;
}
label {
  display: flex;
  gap: 5px;
}

button {
  background-color: #ffff00;
  width: 100%;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: black;
  font-weight: 600;
}

button:hover {
  background-color: #0056b3;
}

.white-text-input {
  color: white;
}
