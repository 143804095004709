.footer {
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
  overflow: hidden;
}

.footer h5 span {
  display: inline-block;
  position: relative;
  padding-bottom: 5px;
}

.footer h5 span::after {
  content: '';
  display: block;
  position: absolute;
  left: 0%;
  bottom: 0;
  transform: none;
  border-bottom: 1px solid #fff;
}

h5 {
  color: #fff;
}

a {
  color: #fff;
  word-break: break-all;
}

.email-container {
  display: inline-block; /* This ensures the div takes only as much width as its content */
  vertical-align: top; /* Aligns it with other inline or inline-block elements */
}

.links {
  text-decoration: none;
  align-items: center;
  height: 100%;
}

.footer ul {
  list-style-type: none;
  padding-left: 0;
}
li {
  padding: 2px;
}

@media (max-width: 768px) {
  .links h5 {
    padding-right: 8px;
  }
  .footer {
    text-align: center;
  }
  .footer h5 span::after {
    width: 100%;
  }
}
/* for screens wider than 768 */
@media (min-width: 768px) {
  .footer h5 span::after {
    width: 105%;
  }
  .footer h5 {
    font-size: 1.4rem;
  }

  .footer a {
    font-size: 1.15rem;
  }
}

/* for screens wider than 992px */
@media (min-width: 992px) {
  .footer h5 span::after {
    width: 165%;
  }
}
