.button-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.button {
  background-color: #feed30;
  border-radius: 10px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  color: black;
  margin: 5px;
  min-width: 160px;
  min-height: 56px;
}

.button:active {
  outline: none;
}
.button:hover {
  background-color: transparent;
  color: #feed30;
}

.button.btn-primary:active {
  outline: none !important;
}
@media (max-width: 768px) {
  .button {
    padding: 5px 5px;
    font-size: 1rem;
  }
}
