.team-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}
.team {
  background-image: url('./../assets/team.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 355px;
  height: 50vh;
  display: flex;
}

.team1 {
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
  /* min-height: 355px; */
  height: 50%;
  flex-wrap: nowrap;
}

.text-center {
  color: #fff;
  font-weight: 700;
  font-size: 4rem;
}
h3 {
  color: #fff;
  font-weight: 700;
  color: #feed30;
}
h4 {
  color: #feed30;
}

.person1,
.person2,
.person3 {
  margin-top: -40%;
  object-fit: cover;
  border-radius: 50%;
}
.member {
  text-align: center;
}
