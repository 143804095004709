.right {
  color: white;
  border-radius: 20px;
  height: 100%;
}

.label-input-container label {
  width: 25%;
  align-items: center;
  margin-right: 10px;
  font-size: 1.25rem;
  font-weight: 500;
}

.file-upload-label {
  font-weight: 500;
  font-size: 1.25rem;
}
.label-input-container input {
  border-radius: 15px;
  color: white;
  font-size: 1.15rem;
  height: 4rem;
  border: 2px solid #ddd;
  width: 90%;
}
.label-input-container input[type='tel'],
input[type='url'] {
  border-radius: 15px;
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
  font-size: 1.25rem;
  width: 90%;
}

.input-container {
  height: 5%;

  background-color: #c991fb;
  padding: 20px;

  border-radius: 15px;
  width: 100%;
}

.select {
  display: flex;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0.5, 0.5, 0.5, 0.3);
  width: 60%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.job-button {
  width: 100%;
  margin: 0, auto;
}

.location {
  color: white;
}
.apply-form {
  border-radius: 10px;
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
}
h3 {
  color: white;
}
.apply-button {
  width: 30%;
  justify-content: center;
  background: 'var(--C4, #FEED30)';
  border-radius: 10px;
}
.job-desire {
  font-size: 1.25rem;
}

.submit {
  width: 40%;
  border-radius: 10px;
  justify-content: flex-end;
}

.buttom {
  /* height: auto; */
}
