.dashborad {
  background-color: white;
  color: black;
}
p {
  color: black;
  font-size: 1rem;
}
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 1024px;
}
.right-sidebar {
  min-height: 1024px;
  width: 100%;
}
.side-report {
  width: 100%;
}

.report1 {
  align-items: center;
  justify-content: space-between;

  width: 100%;
}
.icon {
  width: 28px;
  height: 28px;

  margin-right: 10px;
}

.btn {
  display: flex;
  align-items: center;

  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.btn:hover,
.btn:focus {
  background-color: #491754;
  color: white;
}

.btn:active {
  background-color: #491754;
  color: white;
}

.btn:hover p,
.btn:active p {
  color: white;
}
.dashboard-container {
  background-color: white;
  display: flex;
}
.text-black {
  color: black;
}
.content-area {
  flex-grow: 1;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.right-sidebar {
}
