.navbar {
  background: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 101px;
  width: 100%;
}

#basic-nav-dropdown {
  color: #feed30;
  text-decoration: none;
  border-bottom: 2px solid #feed30;
  padding-bottom: 4px;
}

.logo-image {
  height: auto;
  margin-left: 10%;
}

.custom-toggler-icon {
  padding: 2rem;
}

@media (max-width: 1076px) {
  .navbar {
    padding-top: 2px !important; /* Adjust the top padding as needed for smaller screens */
    padding-bottom: 3px !important;
  }
  .navbar-link {
    font-size: 15px !important; /* Adjust the font size as needed for smaller screens */
    min-width: 120px;
  }
  .logo-image {
    width: 100%;
  }
}

.navbar .container-fluid {
  width: 100%;
}
@media (min-width: 1440px) {
  .navbar .container-fluid {
    width: 78%; /* This will set the container width to 80% for screens larger than 1440px */
    margin-left: auto; /* These will center the container */
    margin-right: auto;
  }
}
