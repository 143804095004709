.contact {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.up {
  background-image: url('../assets/contact.png');
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content {
  width: 80%;
  margin: 0 auto;

  color: #ffffff;
  margin-top: 5%;
}

.contact-container {
  display: flex;
  width: 80%;
  margin: 0 auto;

  background-color: #8b5daf;
  color: #ffffff;
  margin-top: 3%;
  z-index: 1;
  border-radius: 10px;
}

h1 {
  font-weight: 700;
  font-size: 4rem;
}

p {
  font-weight: 500px;
  font-size: 2rem;
}
.buttom {
  background-color: #09152f;
  height: 50%;
}
.left-column {
  display: flex;
  flex-direction: column;
  height: 50%;
  flex: 1;
}
.left {
  width: 80%;
  margin: 3% auto;
}

#contact-form {
  height: 60vh;
  overflow: hidden;
}
#contact-form h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

#contact-form p {
  font-size: 1.5rem;
  margin-bottom: 40px;
  text-align: center;
}

#contact-form label {
  font-size: 1.5rem;
}

#contact-form input[type='text'],
#contact-form input[type='email'] {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 10px;
  border: 2px solid white;
  font-size: 2rem;
  background-color: #8b5daf;
}

#message {
  width: 100%;
  height: 40%;
  overflow: hidden;
  resize: vertical;
  background-color: #8b5daf;
  border-radius: 10px;
  border: 2px solid white;
}
#contact-form button {
  display: block;
  width: auto;
  width: 150px;
  margin-left: auto;
  border: none;
  border-radius: 10px;
  font-size: 1.25rem;
  background-color: #feed30;
}

#contact-form button:hover {
  background-color: #feed30;
}

.right-column {
  flex: 1;
}

.right-column p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.right-column ul {
  list-style: none;
  margin-bottom: 30px;
}

.right-column ul li {
  margin-bottom: 10px;
}

.right-column ul li a {
  color: #ffffff;
  transition: color 0.3s;
  font-size: 1.5rem;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
}

.right-column ul li a:hover {
  color: #8b5daf;
}

hr {
  height: 2px;
  border: none;
  background-color: #ffffff;
}

.confirmation-message {
  font-size: 1.5rem;
  color: #ffffff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  text-align: center;
}

@media screen and (min-width: 1600px) {
  .buttom {
    height: 60%;
  }
}
